
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root',
})
export class EncryptionService {
    constructor () { }

    private makeid (length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    private encryptUsingAES256 (datatocrypt): string {
        const pass = this.makeid(16);
        const _key = CryptoJS.enc.Utf8.parse(pass);
        const _iv = CryptoJS.enc.Utf8.parse(pass);
        const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(datatocrypt), _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }) + pass;
        return encrypted.toString();
    }

    private decryptUsingAES256 (datatodecrypt, pass) {
        const _key = CryptoJS.enc.Utf8.parse(pass);
        const _iv = CryptoJS.enc.Utf8.parse(pass);

        return CryptoJS.AES.decrypt(
            datatodecrypt, _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }).toString(CryptoJS.enc.Utf8);
    }

    encrypt (data): string {
        try {
            return this.encryptUsingAES256(data);
        } catch (error) {
            return '{}';
        }
    }

    private encryptImgUsingAES256 (datatocrypt): string {
        const pass = 'clevermainacvis1';
        const _key = CryptoJS.enc.Utf8.parse(pass);
        const _iv = CryptoJS.enc.Utf8.parse(pass);
        const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(datatocrypt), _key, {
                keySize: 16,
                iv: _iv,
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }

    encryptimgurl (data): string {
        try {
            return this.encryptImgUsingAES256(data);
        } catch (error) {
            return '{}';
        }
    }

    deencrypt (pass, data): string {
        try {
            return this.decryptUsingAES256(data, pass);
        } catch (error) {
            return '{}';
        }
    }
}
