import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Mitarbeiter } from '@app/models/mitarbeiter/Mitarbeiter';
import { Partner } from '@app/models/partner/IPartner';
import { AuthGuardService } from '@app/services/auth-guard.service';
import { ChatService } from '@app/services/chat.service';
import { TerminkalenderService } from '@app/services/terminkalender.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'clever-agent',
    templateUrl: './agent.component.html',
    styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    @Output() triggerclosechatagent: EventEmitter<boolean> = new EventEmitter();
    partner: Partner;
    showChatAgent: boolean = false;
    currentMitarbeiter: Mitarbeiter;
    currentRobotMessage: string = '';

    robotMessages = [
        'Ich kann Sie unterstützen',
        'Wie kann ich Ihnen helfen?',
        'Brauchen Sie Hilfe?',
        'Wir können gerne auch telefonieren',
        'Ich kann Sie per Video-Chat unterstützen',
    ];

    constructor(
        protected guardService: AuthGuardService,
        protected chatService: ChatService,
        protected terminkalenderService: TerminkalenderService,
    ) { }

    ngOnInit(): void {
        this.initPartner();
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    async initPartner() {
        this.guardService.getPartner$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((partner: Partner) => {
            this.partner = partner;
            this.initChatDaten();
        });
    }

    async initChatDaten() {
        if (this.partner?.partnerid?.length > 0) {
            this.terminkalenderService.request({ partnerid: this.partner.partnerid }, 'getchatmitarbeiters').pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe(res => {
                if (!res?.error) {
                    const tmp = [];
                    for (let k = 0; k < res?.length; k++) {
                        if (res[k].userid > 0) {
                            tmp.push(res[k]);
                        }
                    }
                    if (res?.length > 0) {
                        const r = Math.floor(Math.random() * tmp?.length);
                        this.currentMitarbeiter = tmp[r];
                        setTimeout(() => {
                            this.showChatAgent = true;
                            this.updateRobotMessage();
                        }, 30000);
                    }
                }
            });
        }
    }

    async updateRobotMessage() {
        if (this.robotMessages?.length > 0 && this.showChatAgent) {
            const r = Math.floor(Math.random() * this.robotMessages?.length);
            this.currentRobotMessage = this.robotMessages[r];
            setTimeout(() => {
                this.updateRobotMessage();
            }, 50000);
        }
    }

    async closeChatAgent(ev) {
        this.showChatAgent = false;
        this.triggerclosechatagent.emit(true);
    }

    async openChat() {
        this.showChatAgent = false;
        this.chatService.setOpenChat();
        this.triggerclosechatagent.emit(true);
    }
}
