import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from '@angular/material/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';

import { CookiesService } from './services/cookies.service';
import { DateService } from './services/date.service';
import { ChatService } from './services/chat.service';
// import { ScreenService } from '@app/services/screen.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MEDIA_TOKEN } from 'tockens';
import { Partner } from './models/partner/IPartner';
import { SwUpdate } from '@angular/service-worker';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PagerReloadComponent } from './components/pagereload/pagereload.component';
const GRI_DATE_FORMATS: MatDateFormats = {
    ...MAT_NATIVE_DATE_FORMATS,
    display: {
        ...MAT_NATIVE_DATE_FORMATS.display,
        dateInput: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        } as Intl.DateTimeFormatOptions,
    }
};
@Component({
    selector: 'reisen-online',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'reisen.online';
    isBrowser: boolean = false;
    showScrollTopButton: boolean = false;
    showchatpanel: boolean = false;
    showChat: boolean = false;
    hidenNavbar: boolean = false;
    smallMode: boolean = false;
    showChatAgent: boolean = true;
    displaySite: boolean = true;
    pass: string = '';
    currentPartner: Partner;
    protected secret: string = 'cleveracvis';
    protected partnerid: string = '';
    protected lastposition: number = 0;
    protected unsubscribe$ = new Subject();

    constructor(
        protected adapter: DateAdapter<any>,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected cookieService: CookiesService,
        protected dateService: DateService,
        protected chatService: ChatService,
        protected authguard: AuthGuardService,
        @Optional() private update: SwUpdate,
        protected cdr: ChangeDetectorRef,
        private bottomSheet: MatBottomSheet,
        protected mediaObserver: MediaObserver,
        @Optional() @Inject(MEDIA_TOKEN) private media_token: string,
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.smallMode = this.media_token === 'xs';
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            if (environment.production) {
                this.checkUpdate();
            }
            this.checkHidennavbar();
            this.listenOpenChat();
            this.dateService.initCals();
            this.initScroll();
            this.adapter.setLocale('de-DE');
        }
        this.checkPartner();
    }

    async checkUpdate() {
        this.update?.checkForUpdate();

        this.update?.versionUpdates.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(async evt => {
            // console.log('UpdateService: versionUpdates', evt);
            switch (evt.type) {
                case 'VERSION_DETECTED':
                    // console.log(`Downloading new app version: ${evt.version.hash}`);
                    break;
                case 'VERSION_READY':
                    // console.log(`Current app version: ${evt.currentVersion.hash}`);
                    // console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
                    // eslint-disable-next-line no-undef
                    this.updateClient();
                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    // console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                    break;
            }
        });
    }

    updateClient() {
        const sheetRef = this.bottomSheet.open(PagerReloadComponent, {
            data: 'Update verfügbar, bitte aktualisieren'
        });
        sheetRef.afterDismissed().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(async data => {
            if (data && data.message === 'Abbrechen') {
                //
            } if (data && data.message === 'Aktualisieren') {
                await this.update.activateUpdate();
                // eslint-disable-next-line no-undef
                location.reload();
            }
        });
    }

    async initMedia(pp) {
        this.mediaObserver.asObservable().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((change: MediaChange[]) => {
            this.smallMode = change[0].mqAlias === 'xs' || change[0].mqAlias === 'sm' || change[0].mqAlias === 'md';
            this.checkCookis(pp);
        });
    }

    async checkPartner() {
        this.authguard.getPartner$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((pp) => {
            if (pp?.partnerid?.length > 0) {
                this.currentPartner = pp;
                this.showChat = !pp.hideChat;
                this.displaySite = !(pp.partnersite?.length > 0);
                this.initGoogleapi(pp);
                if (this.isBrowser) {
                    this.initMedia(pp);
                    if (this.showChat) {
                        this.chatService.connectToServer();
                    }
                    this.checkSendScreen();
                }
            }
        });
    }

    async initGoogleapi(currentPartner) {
        if (currentPartner.googleapikey?.length > 0 && isPlatformServer(this.platformId)) {
            const gaScript = this.document.createElement('script');
            gaScript.setAttribute('defer', '');
            gaScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${currentPartner.googleapikey}&libraries=places&callback=Function.prototype`);

            this.document.documentElement.firstChild.appendChild(gaScript);
        }
    }

    async checkHidennavbar() {
        this.authguard.gethidenHeaderFooter$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((e) => {
            this.hidenNavbar = e;
            this.cdr.detectChanges();
        });
    }

    async checkSendScreen() {
        // this.chatService.checkScreen()
        //     .pipe(
        //         takeUntil(this.unsubscribe$),
        //     ).subscribe((data: { show: boolean, from: any }) => {
        //         // protected screenService: ScreenService
        //         if (data.show && data?.from?.username?.length > 0) {
        //             this.screenService.sharescreen((canvas) => {
        //                 // console.log(canvas)
        //                 this.chatService.sendScreen(data.from, canvas);
        //             });
        //         }
        //     });
    }

    async initScroll() {
        fromEvent(window, 'scroll').pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((e) => {
            this.lastposition = window.scrollY;
            this.checkScrollPos(this.lastposition);
        });
    }

    openChat(): void {
        this.showchatpanel = true;
    }

    closeChat(): void {
        this.showchatpanel = false;
    }

    closeChatAgent(): void {
        this.showChatAgent = false;
    }

    async listenOpenChat() {
        this.chatService.openchat$.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((open) => {
            if (open) {
                this.openChat();
            }
        });
    }

    checkCookis(prt): void {
        if (this.isBrowser) {
            if (environment.production) {
                if (prt.partnerid?.length > 0) {
                    this.partnerid = prt.partnerid;
                    this.secret = prt.partnersite;
                    if (this.secret?.length > 0) {
                        this.displaySite = window.localStorage.getItem(prt.partnerid + '_pass') === this.secret;
                        if (this.displaySite) {
                            if (!this.detectRobot(navigator.userAgent) && !this.detectChromTest()) {
                                this.cookieService.checkCookis(prt.googleanalyticstrackingid, prt.googletagmgid, prt.googlemapid, prt.facebookpixelid, this.partnerid, this.smallMode);
                            }
                        }
                    } else {
                        if (!this.detectRobot(navigator.userAgent) && !this.detectChromTest()) {
                            this.cookieService.checkCookis(prt.googleanalyticstrackingid, prt.googletagmgid, prt.googlemapid, prt.facebookpixelid, this.partnerid, this.smallMode);
                        }
                    }
                }
            } else {
                this.partnerid = 'main';
                this.displaySite = true;
            }
        }
    }

    detectRobot(userAgent: string): boolean {
        const robots = new RegExp(([
            /bot/, /spider/, /crawl/, // GENERAL TERMS
            /APIs-Google/, /AdsBot/, /Googlebot/, // GOOGLE ROBOTS
            /mediapartners/, /Google Favicon/, /GOOGLE/,
            /FeedFetcher/, /Google-Read-Aloud/,
            /DuplexWeb-Google/, /googleweblight/,
            /bing/, /yandex/, /baidu/, /duckduck/, /yahoo/, // OTHER ENGINES
            /ecosia/, /ia_archiver/, /cortex/,
            /facebook/, /instagram/, /pinterest/, /reddit/, // SOCIAL MEDIA
            /slack/, /twitter/, /whatsapp/, /Whatsapp/, /youtube/,
            /semrush/, // OTHER
        ] as RegExp[]).map((r) => r.source).join('|'), 'i'); // BUILD REGEXP + "i" FLAG

        return robots.test(userAgent);
    }

    detectChromTest(): boolean {
        if (navigator.userAgent.match(/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)/)) {
            return true;
        } else {
            return false;
        }
    }

    checkPass(ev) {
        if (ev.keyCode === 13 && this.pass === this.secret) {
            window.localStorage.setItem(this.partnerid + '_pass', this.secret);
            this.document.body.style.overflow = '';
            this.displaySite = true;
        } else if (ev.keyCode === 13) {
            this.pass = '';
        }
    }

    checkScrollPos(lastposition) {
        // faire quelque chose avec la position du scroll
        if (lastposition >= 500) {
            this.showScrollTopButton = true;
        } else {
            this.showScrollTopButton = false;
        }
    }
}
