<ng-container *ngIf="displaySite else secretSite">
    <ng-container *ngIf="!hidenNavbar else empty">
        <ng-container *ngIf="showChat">
            <ng-container *ngIf="!showchatpanel else chatpanel">
                <div class="mychatdiv mat-elevation-z5" (click)="openChat()">
                    <button matTooltip="Chat starten" matTooltipPosition="left">
                        <mat-icon fontIcon="chat" class="cc1"></mat-icon>
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="isBrowser && showChatAgent && !showchatpanel">
                @if(currentPartner && !currentPartner?.hideChatPopup) {
                <clever-agent (triggerclosechatagent)="closeChatAgent()"></clever-agent>
                }
            </ng-container>
        </ng-container>
    </ng-container>
    <main class="mh">
        <router-outlet></router-outlet>
    </main>
    <ng-container *ngIf="!hidenNavbar">
        <ng-container *ngIf="showScrollTopButton">
            <button mat-mini-fab class="gototop" (click)="scrollToTop()">
                <mat-icon fontIcon="keyboard_arrow_up"></mat-icon>
            </button>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #empty>
    <div class="emptyheader"></div>
</ng-template>

<ng-template #secretSite>
    <div class="secret">
        <div class="subsecret">
            <mat-form-field appearance="outline" class="w100">
                <mat-icon fontIcon="directions_walk" matPrefix></mat-icon>
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" type="password" [(ngModel)]="pass" (keydown)="checkPass($event)"
                    class="bgt">
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template #chatpanel>
    <app-chat (triggerclosechat)="closeChat()"></app-chat>
</ng-template>