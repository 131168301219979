<div class="cc2">
    <div class="cc3" [class.cc3]="mobile" [class.cc3desk]="!mobile">
        <div class="cc4">
            <span class="cc5">Bevor Sie fortfahren</span>
            <!-- <img loading="lazy" src="../../../assets/images/Suntour.reisen_2022_2.svg" height="40" class="cc6"
                alt="suntour-reisen.de"> -->
        </div>
        <div class="cc7">
            <span>
                Wir erheben und verarbeiten auf unserer Website personenbezogene Daten, beispielsweise über Cookies und
                tauschen Daten mit Drittanbietern aus, die uns helfen, unser Angebot zu verbessern, zu finanzieren und
                personalisierte Inhalte darzustellen. Die Verarbeitung kann auch außerhalb der EU / EWR erfolgen, wo
                unter Umständen kein vergleichbares Datenschutzniveau herrscht, z.B. in den USA.
                Die Verarbeitung der Daten erfolgt teilw. auf Basis des berechtigten Interesses, teilw. benötigen wir
                Ihre Zustimmung.<br><br>

                Soweit Sie „Ablehnen“ wählen und Ihre Zustimmung verweigern, können keine individuellen Angebote
                unterbreitet werden, nur notwendige Cookies sind aktiv.<br><br>

                Indem Sie „Akzeptieren“ klicken, stimmen Sie (jederzeit widerruflich) dieser Datenverarbeitung zu. Unter
                „Einstellungen“ können Sie Details konfigurieren und die Datenverarbeitung ablehnen. Weitere
                Informationen finden Sie in unserer <button class="cc8"
                    (click)="openDatenschutz()">Datenschutzerklärung</button> und im <button class="cc8"
                    (click)="openImpressum()">Impressum</button>.<br><br>

                Sie können Ihre Präferenzen jederzeit über die Privatsphäre-Einstellungen im Footer anpassen.
            </span>
        </div>
        <ng-container *ngIf="openConfigs">
            <div style="margin-top: 1rem;">
                <div class="cc1">
                    <div class="cc9">
                        <div class="nameLine">
                            <span class="cc10">Notwendig</span>
                        </div>
                        <mat-icon class="opc6">check_box</mat-icon>
                    </div>
                    <span class="cc11">
                        Diese Cookies und andere Informationen sind für die Funktion unserer Services unbedingt
                        erforderlich. Sie garantieren, dass unser Service sicher und so wie von Ihnen gewünscht
                        funktioniert. Daher kann man sie nicht deaktivieren.
                    </span>
                </div>
                <ng-container *ngFor="let comp of components">
                    <ng-container *ngIf="comp?.id?.length > 0">
                        <div class="cc1">
                            <div class="cc9 clicable" (click)="comp.checked = !comp.checked">
                                <div class="nameLine">
                                    <span class="cc10">{{comp.name}}</span>
                                </div>
                                <ng-container *ngIf="comp.checked">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="!comp.checked">
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-container>
                            </div>
                            <ng-container *ngIf="comp.description?.length > 0">
                                <span class="cc11">{{comp.description}}</span>
                            </ng-container>
                            <ng-container *ngIf="comp.privacy?.length > 0">
                                <a class="cc11" [href]="comp.privacy" target="_blank">{{comp.privacy}}</a>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="cc12">
        <button mat-button (click)="reject()">Ablehnen</button>
        <button mat-button color="primary" *ngIf="!openConfigs"
            (click)="openConfigs = !openConfigs">Konfigurieren</button>
        <button mat-button color="primary" *ngIf="openConfigs" (click)="save()">Auswahl speichern</button>
        <button mat-flat-button color="primary" (click)="allaccept()">Alle akzeptieren</button>
    </div>
</div>