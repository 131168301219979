import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationStart, Params, Router } from '@angular/router';
import { firstValueFrom, tap } from 'rxjs';
import { PartnerService } from './partner.service';
import { AuthGuardService } from './auth-guard.service';
@Injectable(
    { providedIn: 'root' }
)
export class ConfigService {
    public params: Params = {};

    constructor(
        protected partnerService: PartnerService,
        protected guard: AuthGuardService,
        private router: Router) { }

    public init(): Promise<any> {
        return firstValueFrom(this.router.events
            .pipe(
                tap((event) => {
                    if (event instanceof NavigationStart) {
                        // Could add more chars url:path?=;other possible
                        const urlDelimitators = new RegExp(/[?//,;&:#$+=]/);
                        const currentUrlPath = event.url.slice(1).split(urlDelimitators)[0];
                        this.guard.checkPartner(currentUrlPath);
                    }
                })
            ));
    }
}
