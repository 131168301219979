import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CleverInterceptor } from './services/http.service';
import { ChatModule } from './components/chat/chat.module';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { Meta } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localede from '@angular/common/locales/de';
import { CookieModule } from './components/cookie/cookie.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { provideClientHydration } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { ConfigService } from './services/config.service';
import { PagerReloadComponent } from './components/pagereload/pagereload.component';
// import { CustomeUrlSerializer } from './CustomeUrlSerializer';
// import { UrlSerializer } from '@angular/router';
registerLocaleData(localede);

function initializeAppFactory(confs: ConfigService): () => Promise<any> {
    return () => confs.init();
}

@NgModule({
    declarations: [
        AppComponent,
        PagerReloadComponent
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ChatModule,
        MatFormFieldModule,
        CookieModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule
    ],
    providers: [
        Meta,
        // provideHttpClient(withFetch()),
        {
            provide: LOCALE_ID,
            useValue: 'de-DE'
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LfRh1IeAAAAAHtWRke9B5YG-QTnu3HpBhzAckg0'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CleverInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [ConfigService], // <-- Here
            multi: true
        },
        // {
        //     provide: UrlSerializer,
        //     useClass: CustomeUrlSerializer
        // },
        provideClientHydration()
    ]
})
export class AppModule { }

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//     // if (!url.endsWith('/')) {
//     //     url = url + '/';
//     // }
//     // const queryString$ = url.match(/([^?]*)?(.*)/);
//     // if (queryString$[2].length > 0) {
//     //     return /[^\\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//     // }
//     return url;
// };
