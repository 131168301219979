import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet'

@Component({
    selector: 'app-pagereload',
    templateUrl: './pagereload.component.html',
    styleUrls: ['./pagereload.component.scss']
})
export class PagerReloadComponent implements OnInit {
    constructor (private bottomSheetRef: MatBottomSheetRef<PagerReloadComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    name: string;
    clearBar (ev): void {
        this.bottomSheetRef.dismiss({
            message: 'Abbrechen',
            data: this.data
        });
        ev.preventDefault();
    }

    changeStatus () {
        this.bottomSheetRef.dismiss({
            message: 'Aktualisieren',
            data: this.data
        });
    }

    ngOnInit () {
        this.name = this.data;
    }
}
