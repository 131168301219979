import { Injectable, HostListener, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptionService } from './encyption.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { environment } from '../../environments/environment';
import { AuthGuardService } from './auth-guard.service';
import { DOCUMENT } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    private socket: any;
    protected openchat: BehaviorSubject<boolean>;
    protected updateusername$: BehaviorSubject<any>;
    constructor(
        private http: HttpClient,
        private authGuardService: AuthGuardService,
        private encryption: EncryptionService,
        @Inject(DOCUMENT) protected document: any
    ) {
        this.openchat = new BehaviorSubject(false);
        this.updateusername$ = new BehaviorSubject(null);
    }

    async connectToServer() {
        try {
            if (!this.socket) {
                let url = 'https://' + this.document.domain;
                if (!environment.production) {
                    url = 'http://127.0.0.1:3330';
                }
                const namespace = this.authGuardService.getPartnerId()?.replace(/-/g, '_');
                const usrnm = window.localStorage.getItem(this.authGuardService.getPartnerId() + '_sessionid');
                const prtid = this.authGuardService.getPartnerId();
                this.socket = io(url + '/' + namespace, {
                    transports: ['websocket'],
                    query: {
                        web: true,
                        username: usrnm,
                        partnerid: prtid,
                        namespace: namespace
                    }
                });
                this.socket.on('connection_error', () => {
                    console.log('connection_error');
                });

                this.socket.on('kill', () => {
                    this.socket.removeAllListeners();
                    this.socket.disconnect();
                });

                this.checkusername();
            }
        } catch (error) {
            console.log(error);
        }
    }

    closeconnection() {
        this.socket.removeAllListeners();
        this.socket = null;
    }

    checkusername() {
        this.socket.on('updatename', (data) => {
            window.localStorage.setItem(this.authGuardService.getPartnerId() + '_sessionid', data.username);
            this.updateusername$.next(data);
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.socket && this.socket.disconnect();
        this.socket = null;
    }

    get openchat$(): Observable<any> {
        return this.openchat.asObservable()
    }

    setOpenChat() {
        this.openchat.next(true);
    }

    // EMITTER
    sendMessage<T>(message: T, cb) {
        try {
            if (message) {
                this.socket.emit('sendMessage', message, cb);
            } else {
                this.socket.removeAllListeners();
            }
        } catch (error) {
            this.socket.removeAllListeners();
        }
    }

    // get my messages
    getmymesages<T, A>(username: T, cb: (data: A) => void) {
        try {
            if (username) {
                this.socket.emit('getmymesages', { username: username }, cb);
            } else {
                this.socket.removeAllListeners();
            }
        } catch (error) {
            this.socket.removeAllListeners();
        }
    }

    // HANDLER
    onNewMessage<T>() {
        return new Observable(observer => {
            this.socket.on('msgfrombck', (message: T) => {
                observer.next(message);
            });
        });
    }

    // HANDLER typing
    onTyping() {
        return new Observable(observer => {
            this.socket.on('sendTyping', (typing) => {
                observer.next(typing);
            });
        });
    }

    // HANDLER screen
    checkScreen() {
        return new Observable(observer => {
            this.socket.on('sendsmm', (typing) => {
                observer.next(typing);
            });
        });
    }

    // HANDLER send screen
    sendScreen(user, data) {
        try {
            if (user) {
                this.socket.emit('getsmm', { user: user, data: data });
            } else {
                this.socket.removeAllListeners();
            }
        } catch (error) {
            this.socket.removeAllListeners();
        }
    }

    imgPermission() {
        return new Observable(observer => {
            this.socket?.on('imgPermission', (prm) => {
                observer.next(prm);
            });
        });
    }

    // HANDLER typing
    onsendStopRobot() {
        return new Observable(observer => {
            this.socket.on('sendStopRobot', (data) => {
                observer.next(data);
            });
        });
    }

    // Send Typing
    sendTyping(user, typing) {
        try {
            if (user) {
                this.socket.emit('sendTyping', { user: user, typing: typing });
            } else {
                this.socket.removeAllListeners();
            }
        } catch (error) {
            this.socket.removeAllListeners();
        }
    }

    // HANDLER
    updateusername() {
        return this.updateusername$.asObservable();
    }

    resetUsername(oldname) {
        this.socket.emit('resetwebusername', { newname: '', oldname: oldname });
    }

    sendemail(data, cb) {
        this.socket.emit('sendEmail', data, cb);
    }

    request(obj: Object, suffix: string): Observable<any> {
        obj = this.encryption.encrypt(obj);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'ngsw-bypass': ''
            })
        };
        return this.http.post('api/' + suffix, { body: obj }, httpOptions)
            .pipe(
                distinctUntilChanged()
            );
    }
}
