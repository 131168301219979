import { Injectable, HostListener, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CookieComponent } from '../components/cookie/cookie.component';
import { GoogleService } from './google.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacebookService } from './facebook.service';
// import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class CookiesService implements OnDestroy {
    private unsubscribe$ = new Subject();
    constructor(
        protected dialog: MatDialog,
        protected googleServie: GoogleService,
        protected facebookService: FacebookService
    ) { }

    async checkCookis(googleId, googleTagId, googleMapsId, facebookpixelid, partnerid, mobile) {
        if (window.location.pathname.startsWith('/reisen')) {
            partnerid = 'main';
        }
        let isConsented = this.getCookie(partnerid + '_COOKIE_CONSENT') === '1';

        const isConsentedAnalytics_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS_WID') === '1';
        const isConsentedGoogleTagMg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER_WID') === '1';
        const isConsentedFacebookPixelMg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL_WID') === '1';
        const isConsentedGoogleMapsg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS_WID') === '1';
        const isConsentedYoutubeg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE_WID') === '1';

        const isConsentedAnalytics = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS') === '1';
        const isConsentedGoogleTagMg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER') === '1';
        const isConsentedFacebookPixelMg = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL') === '1';
        const isConsentedGoogleMapsg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS') === '1';
        const isConsentedYOUTUBEg = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE') === '1';

        if (
            (!isConsentedAnalytics && !isConsentedAnalytics_WID && googleId?.length > 0) ||
            (!isConsentedGoogleTagMg && !isConsentedGoogleTagMg_WID && googleTagId?.length > 0) ||
            (!isConsentedGoogleMapsg && !isConsentedGoogleMapsg_WID && googleMapsId?.length > 0) ||
            (!isConsentedYOUTUBEg && !isConsentedYoutubeg_WID) ||
            (!isConsentedFacebookPixelMg && !isConsentedFacebookPixelMg_WID && facebookpixelid?.length > 0)
        ) {
            isConsented = false;
        }
        if (!isConsented) {
            this.openCookies(googleId, googleTagId, googleMapsId, facebookpixelid, true, partnerid, mobile);
        } else {
            if (isConsentedAnalytics && googleId?.length > 0) {
                this.setGAnalytics(googleId);
            }

            if (isConsentedGoogleTagMg && googleTagId?.length > 0) {
                this.setGoogleTagManager(googleTagId);
            }

            if (isConsentedGoogleMapsg && googleMapsId?.length > 0) {
                this.setGoogleMap(googleMapsId);
            }

            if (isConsentedFacebookPixelMg && facebookpixelid?.length > 0) {
                this.setFacebookPixelManager(facebookpixelid);
            }
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    openCookies(googleId, googleTagId, googleMapsId, facebookpixelid, auto, partnerid, mobile) {
        const isConsentedAnalytics = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS') === '1';
        const isConsentedGoogleTagMg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER') === '1';
        const isConsentedGoogleMaps = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS') === '1';
        const isConsentedYouTube = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE') === '1';
        const isConsentedFacebookPixelMg = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL') === '1';
        const config = new MatDialogConfig();
        config.panelClass = 'cookiePanel';
        config.maxWidth = '600px';
        if (mobile) {
            config.height = '100%';
        }
        config.autoFocus = false;
        if (auto) {
            config.hasBackdrop = false;
            config.position = { bottom: '.5rem' };
        }
        config.data = {
            components: [
                {
                    name: 'Google Analytics',
                    checked: isConsentedAnalytics,
                    id: googleId,
                    description: 'Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren.',
                    privacy: 'https://policies.google.com/privacy'
                },
                {
                    name: 'Google Tag Manager',
                    checked: isConsentedGoogleTagMg,
                    id: googleTagId,
                    description: 'Google Tag-Management-System. Damit kann der Benutzer Messcodes und zugehörige Codefragmente aktualisieren, die auf der Website des Benutzers oder in der mobilen App als Tags bezeichnet werden.',
                    privacy: 'https://policies.google.com/privacy'
                },
                {
                    name: 'Google Maps',
                    checked: isConsentedGoogleMaps,
                    id: googleMapsId,
                    description: 'Google Maps ist ein Web-Mapping-Dienst. Die gesammelten Daten werden für das anzeigen von Karten verwendet.',
                    privacy: 'https://policies.google.com/privacy'
                },
                {
                    name: 'YouTube',
                    checked: isConsentedYouTube,
                    id: 'YouTube',
                    description: 'Wird verwendet, um integrierte Inhalte der Videoplattform YouTube auf unserer Website freizuschalten. Die durch die Cookies erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden.',
                    privacy: 'https://policies.google.com/privacy'
                },
                {
                    name: 'Facebook Pixel',
                    checked: isConsentedFacebookPixelMg,
                    id: facebookpixelid,
                    description: 'Das ist ein Werbung-messen-System.'
                }
            ]
        };

        const dialogRef = this.dialog.open(CookieComponent, config);
        dialogRef.afterClosed().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((result: any[]) => {
            if (result) {
                this.resetCookies();
                for (let i = 0; i < result?.length; i++) {
                    if (result[i].name === 'Google Analytics') {
                        const val = result[i].checked ? '1' : '0';
                        if (val === '1') {
                            this.setGAnalytics(result[i].id);
                        }
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS', val, 730, partnerid);
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                    }
                    if (result[i].name === 'Google Tag Manager') {
                        const val = result[i].checked ? '1' : '0';
                        if (val === '1') {
                            this.setGoogleTagManager(result[i].id);
                        }
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER', val, 730, partnerid);
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                    }
                    if (result[i].name === 'Google Maps') {
                        const val = result[i].checked ? '1' : '0';
                        if (val === '1') {
                            this.setGoogleMap(result[i].id);
                        }
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS', val, 730, partnerid);
                        this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                    }
                    if (result[i].name === 'YouTube') {
                        const val = result[i].checked ? '1' : '0';
                        this.setCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE', val, 730, partnerid);
                        this.setCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                    }
                    if (result[i].name === 'Facebook Pixel') {
                        const val = result[i].checked ? '1' : '0';
                        if (val === '1') {
                            this.setFacebookPixelManager(result[i].id);
                        }
                        this.setCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL', val, 730, partnerid);
                        this.setCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                    }
                }

                this.setCookie(partnerid + '_COOKIE_CONSENT', '1', 730, partnerid);
                // this.setCookie(partnerid + '_COOKIE_CONSENT_RES', moment().format('YYYY-MM-DD'), 730, partnerid);
                window.location.reload();
            }
        });
    }

    setGAnalytics(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleAnalytics(id);
        }
    }

    setGoogleTagManager(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleTagManager(id);
        }
    }

    setGoogleMap(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleMap(id);
        }
    }

    setFacebookPixelManager(id) {
        if (id?.length > 0) {
            this.facebookService.loadFacebookPixelManager(id);
        }
    }

    resetCookies(): void {
        const allCookies = document.cookie.split(';');

        // The "expire" attribute of every cookie is
        // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
        for (let i = 0; i < allCookies.length; i++) {
            document.cookie = allCookies[i] + '=;expires=' +
                new Date(0).toUTCString();
        }
    }

    getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    setCookie(name: string, value: string, expireDays: number, path: string = '') {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires: string = `expires=${d.toUTCString()}`;
        const cpath: string = path ? '; path=/' : '';
        // const cpath1: string = `; path=/${path}`;
        // document.cookie = `${name}=${value}; expires=Thu, 01 Jan 1970 00:00:00 GMT${cpath1}`;
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
}
